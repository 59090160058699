import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Usage`}</h2>
    <p>{`Elevation is used to indicate hierarchy and create focus through the use of depth. Components with the same elevations create an effect of grouping and similarity between content while components on different elevations reflect spatial relationships.
Elevation is depicted through shadows to create an effect of depth. Components closer to the base layer have sharper shadows, while components that are elevated have softer shadows. You can chek out the
guideline for more detail description.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { elevation } from "@legion-ui/core";
`}</code></pre>
    <div className="divi" />
    <h2>{`Preview`}</h2>
    <p>{`Please note that each value of the elevation property is determined by the token from each theme.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Token Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Box Shadow`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation0" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation0`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 0px 0px 2px #EEAFAF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation1" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 1px 0px rgba(0, 0, 0, 0.05)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation2" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation3" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation4" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 4px 20px rgba(0, 0, 0, 0.15), 0px 0px 3px rgba(0, 0, 0, 0.1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><div className="elevation5" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`elevation5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There are two ways on how you can use the `}<inlineCode parentName="p">{`elevation`}</inlineCode>{` toolkit. First by using Legion V2’s components and second by using HTML elements.`}</p>
    <h3>{`Component`}</h3>
    <p>{`For Legion V2 usage, please refer to each component’s documentation page for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box shadow="elevation0">...</Box>
  <Box shadow="elevation1">...</Box>
  <Box shadow="elevation2">...</Box>
  <Box shadow="elevation3">...</Box>
  <Box shadow="elevation4">...</Box>
  <Box shadow="elevation5">...</Box>
`}</code></pre>
    <h3>{`HTML Elements`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <div style={{ boxShadow: \`\${elevation.elevation1}\` }}>...</div>
  <div style={{ boxShadow: \`\${elevation.elevation2}\` }}>...</div>
  <div style={{ boxShadow: \`\${elevation.elevation3}\` }}>...</div>
  <div style={{ boxShadow: \`\${elevation.elevation4}\` }}>...</div>
  <div style={{ boxShadow: \`\${elevation.elevation5}\` }}>...</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      